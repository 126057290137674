/* Variables */
$primary: #073c58;
$primary-contrast: #31e86d;
$secondary: #f0f3f5;
$blue: #dce2e6;
$blue-selected: #b1bcc3;
$blue-label: #e8edef;
$darkblue: #4c738a;
$lightblue: #F0F3F5;
$button: #0064B3;
$green: #00E64B;
$black: #000000;
$gray: #ACACAC;
$lightgray: #EAECEF;
$darkgray: #5D5D5D;
$white: #FFFFFF;
$input-border: #ced4da;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "blue": $blue,
        "blue-selected": $blue-selected,
        "blue-label": $blue-label,
        "darkblue": $darkblue,
        "lightblue": $lightblue,
        "button": $button,
        "green": $green,
        "black": $black,
        "gray": $gray,
        "lightgray": $lightgray,
        "darkgray": $darkgray,
        "white": $white,
        "input-border": $input-border
);

$body-background-color: #F0F3F5;
$body-text-color: #0b3d57;
$button-hover-color: #0b546e;
$green: #00E64B;
$blue: #DCE2E6;

$custom-range-thumb-bg: $primary;
$custom-range-thumb-active-bg: $darkblue;

$primevue-font-path: "~primeicons/fonts/";
@import "~primevue/resources/themes/saga-green/theme.css";
@import "~primeicons/primeicons.css";
@import "~primevue/resources/primevue.min.css";

@font-face {
  font-family: 'primeicons';
  src: url('#{$primevue-font-path}primeicons.eot');
  src: url('#{$primevue-font-path}primeicons.eot?#iefix') format('embedded-opentype'),
       url('#{$primevue-font-path}primeicons.woff2') format('woff2'),
       url('#{$primevue-font-path}primeicons.woff') format('woff'),
       url('#{$primevue-font-path}primeicons.ttf') format('truetype'),
       url('#{$primevue-font-path}primeicons.svg#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.primeDiv {
  font-family: 'primeicons' !important; 
  border: 4px solid;
}
/* Imports */
@import "~bootstrap/scss/bootstrap";
@import "./addons";

$roboto-font-path: '~roboto-fontface/fonts';
@import "~roboto-fontface/css/roboto/sass/roboto-fontface.scss";
@import "~@fontsource/montserrat/600.css";

@import "~pdfjs-dist/web/pdf_viewer.css";


/* Default settings */
@include media-breakpoint-down(sm) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}
@include media-breakpoint-up(sm){
  h1 {
    font-size: 1.9rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}
a {
  text-decoration: none;
  color: var(--primary);

  &:hover {
    text-decoration: underline;
    color: var(--primary);
  }
}

/* Icon colors */
@each $color, $value in $theme-colors {
  .icon-#{$color} path {
    stroke: $value !important;
  }
}

.play-icon-active {
  color: $green
}

.play-icon {
  color: $primary
}

/* Containers */
.container-fluid {
  //max-width: 1920px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}

/* Forms */
.btn {
  font-size: 1.1rem;
  min-height: 50px;
}
.btn-primary {
  @include button-variant(theme-color("primary"), theme-color("primary"), $button-hover-color, darken(theme-color("primary"), 0%), darken(theme-color("primary"), 0%), darken(theme-color("primary"), 0%));

  color: var(--white) !important;

  &:hover, &:focus, &.focus, &:active, &.active {
    background-color: var(--primary) !important;
  }
}
.btn-outline-primary {
  @include button-outline-variant(theme-color("primary"), $white);

  color: var(--primary) !important;
}
.btn-secondary {
}
.btn-blue {
  @include button-variant(theme-color("blue"), theme-color("blue"), darken(theme-color("blue"), 5%), darken(theme-color("blue"), 0%), darken(theme-color("blue"), 0%), darken(theme-color("blue"), 0%));
}
input.form-control.no-form-control {
  border-radius: 0 !important;
  padding: initial !important;
  margin: initial !important;
  border-color: initial !important;
  border-width: 1px !important;
  height: 30px !important;
  width: initial !important;
  display: initial !important;
  outline: none !important;
  box-shadow: none !important;
}
input.form-control {
  height: 50px !important;
}
input.form-control.form-control-sm {
  height: 30px !important;
}
select.form-control {
  height: 50px !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control::-ms-expand {
  display: none;
}
.select2-container .select2-selection--single {
  height: 50px !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  border-color: #CED4DA !important;
  outline: none;
}
.select2-container--disabled .select2-selection--single {
  background-color: #e9ecef !important;
}
.select2-container .select2-search__field {
  outline: none;
  border-radius: 4px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 49px !important;
  padding-left: 12px !important;
}
.select2-container .select2-selection__arrow b {
  display: none !important;
}
.select2-container .select2-results__options {
  max-height: 300px !important;
}
label.required::after {
  content: "*";
  color: #dc3545;
  position: relative;
  left: 3px;
  bottom: 2px;
  font-size: 14px;
}

/* Datepickers */
.datepicker {
  .active {
    color: black !important;
  }
}

/* Checkbox slider */
.switch-footer {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch-footer input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-footer .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $blue;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch-footer .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #FFF;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch-footer input:checked + .slider {
  background-color: $green;
}
.switch-footer input:focus + .slider {
  box-shadow: 0 0 0 $green;
}
.switch-footer input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch-footer .slider.round {
  border-radius: 34px;
}
.switch-footer .slider.round:before {
  border-radius: 50%;
}
.switch-meeting {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 68px;
}
.switch-meeting input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-meeting .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $blue;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch-meeting .slider:before {
  position: absolute;
  content: "";
  height: 52px;
  width: 52px;
  left: 8px;
  bottom: 8px;
  background-color: #FFF;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch-meeting input:checked + .slider {
  background-color: $green;
}
.switch-meeting input:focus + .slider {
  box-shadow: 0 0 0 $green;
}
.switch-meeting input:checked + .slider:before {
  -webkit-transform: translateX(52px);
  -ms-transform: translateX(52px);
  transform: translateX(52px);
}
.switch-meeting .slider.round {
  border-radius: 34px;
}
.switch-meeting .slider.round:before {
  border-radius: 50%;
}
.switch-radio {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.switch-radio input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-radio .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $blue;
  -webkit-transition: .2s;
  transition: .2s;
}

.switch-radio:focus {
  outline: solid skyblue 1px;
}

.switch-radio .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #FFF;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch-radio input:checked + .slider {
  background-color: $green;
}
.switch-radio input:focus + .slider {
  box-shadow: 0 0 0 $green;
}
.switch-radio input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
.switch-radio .slider.round {
  border-radius: 34px;
}
.switch-radio .slider.round:before {
  border-radius: 50%;
}

/* Custom cursors */
.cursor-highlighter {
  cursor: url('../assets/cursor-highlighter.png') 0 15, auto;
}
.cursor-pencil {
  cursor: url('../assets/cursor-pencil-alt.png') 0 15, auto;
}
.cursor-hand {
  cursor: url('../assets/cursor-hand.png') 0 15, auto;
}
.cursor-eraser {
  cursor: url('../assets/cursor-eraser.png') 0 15, auto;
}

/* Online / Offline dots */
.dot-online {
  background-color: $green;
  border-radius: 50%;
  display: inline-block;
}
.dot-busy {
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}
.dot-offline {
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

/* Modals */
.modal-call-agent {
  padding: 0 !important;

  .modal-content {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
  }
  .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    margin: 0;
  }
  .modal-content {
    border: 0;
    border-radius: 0;
  }
}
#modal-no-video {
  .modal-content {
    p {
      font-size: 16px;
    }
  }
}

/* iframe website button */
html.iframe-button,
html.iframe-button body {
  height: auto !important;
  width: 300px;
  background-color: transparent !important;
}
html.iframe-button body h1 {
  font-size: 22px;
  font-weight: normal;
}

/* iframe website form */
html.iframe-form,
html.iframe-form body {
  height: auto;
  width: 100%;
}
@include media-breakpoint-down(sm) {
  h1 {
    font-size: 18px;
  }
}
@include media-breakpoint-up(sm){
  h1 {
    font-size: 22px;
  }
}

/* Custom */
html {
  height: 100%;
  touch-action: pan-x pan-y;
}
body {
  background-color: $body-background-color;
  font-family: "Roboto", sans-serif;
  color: $body-text-color;
  height: 100%;
  touch-action: pan-x pan-y;
}
body * {
  font-family: "Roboto", sans-serif;
}
.logo {
  width: 233px;
  height: 43px;
}
.custom_logo {
  max-width: 250px;
  max-height: 70px;
}
.logo_footer {
  max-height: 60px;
  max-width: 120px;
}
.b-toaster {
  z-index: 999999 !important;
}

.chat-url {
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: underline;
    color: inherit !important;
}

.chat-arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--primary);
}
.chat-arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--secondary);
}
.video-rotate-phone {
  transform: rotate(225deg);
}
.webrtc-small-video {
  -webkit-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  -moz-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);

  .webrtc-video, canvas {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.webrtc-video-small-container-width {
  .webrtc-video {
    object-fit: cover;
  }

  @include media-breakpoint-down(sm) {
    width: 150px;
  }
  @include media-breakpoint-up(sm) {
    width: 170px;
  }
  @include media-breakpoint-up(md) {
    width: 190px;
  }
  @include media-breakpoint-up(lg) {
    width: 230px;
  }
  @include media-breakpoint-up(xl) {
    width: 280px;
  }

  &.scale-video {
    @include media-breakpoint-down(sm) {
      width: 80px;
    }
    @include media-breakpoint-up(sm) {
      width: 100px;
    }
    @include media-breakpoint-up(md) {
      width: 120px;
    }
    @include media-breakpoint-up(lg) {
      width: 140px;
    }
    @include media-breakpoint-up(xl) {
      width: 180px;
    }
  }
}
.webrtc-video-small-container-height {
  .webrtc-video {
    object-fit: cover;
  }

  @include media-breakpoint-down(sm) {
    height: 150px;
  }
  @include media-breakpoint-up(sm) {
    height: 170px;
  }
  @include media-breakpoint-up(md) {
    height: 190px;
  }
  @include media-breakpoint-up(lg) {
    height: 230px;
  }
  @include media-breakpoint-up(xl) {
    height: 280px;
  }

  &.scale-video {
    @include media-breakpoint-down(sm) {
      height: 80px;
    }
    @include media-breakpoint-up(sm) {
      height: 100px;
    }
    @include media-breakpoint-up(md) {
      height: 120px;
    }
    @include media-breakpoint-up(lg) {
      height: 140px;
    }
    @include media-breakpoint-up(xl) {
      height: 180px;
    }
  }
}

.webrtc-video-big-container {
  display: grid;
  grid-auto-flow: row dense;
  gap: 0;
}
.webrtc-video-big-container.large-meeting {
  width: 100%;
  height: 100%;

  .webrtc-remote-video-container {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }
}
.webrtc-video-big-container > div {
  justify-self: center;
  align-self: center;
  grid-row: auto / span 1;
  order: 2;
  border: 1px solid var(--dark);
}
.webrtc-video-big-container:not(.large-meeting) > div.portrait {
  grid-row: auto / span 2;
  order: 1;
}

.webrtc-video-big-container-participant {
  max-height: 100vh;
}
.webrtc-remote-video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .webrtc-video {
    object-fit: cover;
  }

  .webrtc-remote-video-real_name {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    z-index: 3;
    text-align: center;
    align-self: center;
    min-width: 150px;
    max-width: 300px;
    border-radius: 0 0 10px 10px;
    padding-left: 5px;
    padding-right: 5px;
    opacity: 0.6;
    background-color: var(--lightgray);
    color: var(--darkgray);
  }
  .webrtc-remote-video-real_name.local {
    background-color: var(--primary);
    color: var(--white);
  }

  .webrtc-remote-video-disconnected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: none;
    background-color: var(--blue);
    display: flex;
    align-items: center;
  }
  .webrtc-remote-video-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: none;
    display: flex;
    align-items: center;
  }
  .webrtc-remote-video-hand-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: none;
    display: flex;
    align-items: center;
  }
}
.webrtc_screen {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.webrtc-small-video-hardwaretest {
  -webkit-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  -moz-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
}
.webrtc-small-video-cam-settings {
  //-webkit-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  //-moz-box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);
  //box-shadow: -8px 8px 8px 0 rgba(0, 0, 0, 0.60);

  .webrtc-video {
    object-fit: cover;

    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
.webrtc-small-video-cam-settings-canvas {
  object-fit: cover;

  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;

  canvas {
    max-width: 100%;
    max-height: 100%;
  }
}
.webrtc-hardwaretest-video {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}
#videoContainerLocal:not(.not-mirrored) video, #local:not(.not-mirrored) video, #video_local:not(.not-mirrored) video {
  -ms-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
#videoContainerLocal:not(.not-mirrored) canvas, #local:not(.not-mirrored) canvas, #video_local:not(.not-mirrored) canvas {
  -ms-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

*::-webkit-media-controls-panel,
*::-webkit-media-controls-play-button *::-webkit-media-controls-volume-slider-container *::-webkit-media-controls-volume-slider,
*::-webkit-media-controls-mute-button,
*::-webkit-media-controls-timeline,
*::-webkit-media-controls-current-time-display,
*::-webkit-full-page-media::-webkit-media-controls-panel,
*::-webkit-media-controls-timeline-container,
*::-webkit-media-controls-time-remaining-display *::-webkit-media-controls-seek-back-button *::-webkit-media-controls-seek-forward-button *::-webkit-media-controls-fullscreen-button *::-webkit-media-controls-rewind-button *::-webkit-media-controls-return-to-realtime-button *::-webkit-media-controls-toggle-closed-captions-button *::-webkit-media-controls-pause-button *::-webkit-media-controls-fullscreen-button,
*::-webkit-media-controls-panel,
*::-webkit-media-controls-play-button,
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none !important;
}
::-webkit-media-controls {
  display:none !important;
  -webkit-appearance: none !important;
}
video::-webkit-media-controls-enclosure {
  display:none !important;
  -webkit-appearance: none !important;
}
video {
  pointer-events: none !important;
}
.footer {
  a {
    text-decoration: none !important;

    &:hover, &:active, &:visited {
      background-color: var(--secondary) !important;
    }
  }
  a.disabled {
    text-decoration: none !important;
    color: var(--gray);
    cursor: initial;

    &:hover, &:active, &:visited {
      background-color: transparent !important;
    }
  }
}

.fs-file-selector .fs-droppable input[type="file"] {
  position: absolute !important;
  left: -10000px !important;
}

.breaker {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
  display: none;
}

/*
4n Because every video comes with a breaker div, so 4n is the breaker div of the 2nd video
.breaker:nth-child(2n) does NOT work
*/
#videoContainerRemote.break_per_1 div:nth-child(2n) {
  display: inline-block;
}

#videoContainerRemote.break_per_1 div:last-child {
  display: none !important;
}

#videoContainerRemote.break_per_2 div:nth-child(4n) {
  display: inline-block;
}

#videoContainerRemote.break_per_2 div:last-child {
  display: none !important;
}

#videoContainerRemote.break_per_3 div:nth-child(6n) {
  display: inline-block;
}

#videoContainerRemote.break_per_3 div:last-child {
  display: none !important;
}

#videoContainerRemote.break_per_4 div:nth-child(8n) {
  display: inline-block;
}

#videoContainerRemote.break_per_4 div:last-child {
  display: none !important;
}

.datepicker .disabled {
  color: #BABBBD !important;
}
.datepicker .cw {
  color: #BABBBD !important;
}
.datepicker .today {
  font-weight: bold !important;
  color: black !important;
}

.toast.bg-danger {
  color: #fff !important;
}

.text-end{
  text-align: end
}
